<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              :disabled="isNew || posted"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-2">
      <v-tabs class="custom-tabs" color="success" v-model="tab">
        <v-tab>Реквізити</v-tab>
        <v-tab>Відбір</v-tab>

        <v-tab-item class="mt-4 pb-1">
          <v-form v-model="formValid" ref="form">
            <v-row class="px-2">
              <v-col cols="12">
                <v-textarea
                    v-model="title_"
                    filled
                    label="Заголовок оголошення"
                    hide-details
                    required :rules="[v => !!v || 'Це поле є обов’язковим']"
                    :class="title_ ? '' : 'req-star'"
                    color="grey"
                    auto-grow
                    rows="1"
                />
              </v-col>
              <v-col cols="12">
                  <v-textarea
                      v-model="message"
                      filled
                      label="Текст оголошення"
                      hide-details
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="message ? '' : 'req-star'"
                      color="grey"
                      auto-grow
                      rows="2"
                  />
              </v-col>
              <v-col cols="12">
                <v-radio-group v-model="message_level"
                               row
                               hide-details required
                               class="mt-0 ml-1"
                               :rules="[v => !!v || 'Це поле є обов’язковим']">
                  <template v-slot:label>
                    <div>Рівень важливості оголошення</div>
                  </template>
                  <v-radio :value="1" color="grey darken-1">
                    <template v-slot:label>
                      <div class="teal--text">Звичайний</div>
                    </template>
                  </v-radio>
                  <v-radio :value="2" color="grey darken-1">
                    <template v-slot:label>
                      <div class="success--text">Важливий</div>
                    </template>
                  </v-radio>
                  <v-radio :value="3" color="grey darken-1">
                    <template v-slot:label>
                      <div class="error--text">Надважливий</div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="6">
                <date-component v-model="show_end_date"
                                label="Показувати до"
                                filled
                />
              </v-col>
              <v-col cols="6">
                <v-select
                    v-model="user_group"
                    :items="user_group_select"
                    filled label="Група отримувачів"
                    hide-details
                    required :rules="[v => !!v || 'Це поле є обов’язковим']"
                    :class="user_group ? '' : 'req-star'"
                    color="grey"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-tab-item>
        <v-tab-item class="mt-4 pb-1">
          <v-row class="dense-wrapper px-2" v-if="filters">
            <v-col cols="12" class="pt-0">
              <v-switch
                  class="mt-1"
                  v-model="filters.all_flats"
                  hide-details
                  label="Усі абоненти організації"
                  color="secondary"/>
            </v-col>
            <v-col cols="12">
              <AddressElementSelect
                  label="Об’єднана громада" v-model="filters.amalgamated_hromada_id"
                  filled select_type="amalgamated_hromada" :disabled="filters.all_flats"
              />
            </v-col>
            <v-col cols="12">
              <AddressElementSelect
                  label="Населений пункт" v-model="filters.city_id"
                  :parent_id="filters.amalgamated_hromada_id" :use_parent_id="true"
                  filled select_type="city" :disabled="filters.all_flats"
              />
            </v-col>
            <v-col cols="12">
              <AddressElementSelect
                  label="Район міста" v-model="filters.city_area_id"
                  :parent_id="filters.city_id" :use_parent_id="true"
                  filled select_type="city-area" :disabled="filters.all_flats"
              />
            </v-col>
            <v-col cols="12" v-if="filters.city_area_id">
              <AddressElementSelect
                  label="Вулиця" v-model="filters.streets" multiple
                  :parent_id="filters.city_area_id" :use_parent_id="true"
                  filled select_type="street-with-city-area" :disabled="filters.all_flats"
              />
            </v-col>
            <v-col cols="12" v-else>
              <AddressElementSelect
                  label="Вулиця(-ці)" v-model="filters.streets" multiple
                  :parent_id="filters.city_id" :use_parent_id="true"
                  filled select_type="street" :disabled="filters.all_flats"
              />
            </v-col>
            <v-col cols="12">
              <AddressElementSelect
                  label="Будинок(-ки)" v-model="filters.buildings" multiple
                  :parent_id="filters.streets" :use_parent_id="true"
                  filled select_type="building" :disabled="filters.all_flats"
              />
            </v-col>
            <v-col cols="12" md="12">
              <Checker :value="filters.checker_id" @autocompleteChange="onCheckerChange"/>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="success darken-1" @click="crud_item(true)" :loading="getModalLoading" :disabled="isNew"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Опублікувати
      </v-btn>
      <v-btn depressed text color="secondary darken-1" @click="crud_item(false)" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import {
  CREATE_USER_MESSAGE_HISTORY, UPDATE_USER_MESSAGE_HISTORY, DELETE_USER_MESSAGE_HISTORY
} from "@/store/actions/user_message_history";

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'user_message_history_modal_delete'

const DEFAULT_FILTERS_STRING = {
  all_flats: false,
  checker_id: null,
  amalgamated_hromada_id: null,
  city_id: null,
  city_area_id: null,
  streets: [],
  buildings: []
}

export default {
  name: "HWP_Modal_UserMessageHistory",
  mixins: [ModalComponentMixin],
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
    Checker: () => import("@/components/autocomplite/Checker")
  },
  data() {
    return {
      title_: this.item.title,
      message: this.item.message,
      show_end_date: this.item.show_end_date || null,
      message_level: this.item.message_level || 1,
      posted: this.item.posted || false,
      posted_date: this.item.posted_date,
      user_group: this.item.user_group || 'all',
      filters: JSON.parse(this.item.filters || JSON.stringify(DEFAULT_FILTERS_STRING)),
      user_group_select: [
        { text: 'Усі', value: 'all' },
        { text: 'Персональний кабінет', value: 'cabinet' },
      ],
      tab: 0
    }
  },
  methods: {
    onCheckerChange(payload) {
      this.filters.checker_id = (payload || {}).value || null
    },
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.title_ = this.item.title
      this.message = this.item.message
      this.show_end_date = this.item.show_end_date || null
      this.message_level = this.item.message_level || 1
      this.posted = this.item.posted
      this.posted_date = this.item.posted_date
      this.user_group = this.item.user_group
      this.filters = JSON.parse(this.item.filters || JSON.stringify(DEFAULT_FILTERS_STRING))

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      this.tab = 0
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: 'Підтвердіть вилучення оголошення для користувачів',
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item(posted=false) {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Текст повідомлення, важливість повідомлення та група отримувачів мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        title: this.title_,
        message: this.message,
        show_end_date: this.show_end_date || null,
        message_level: this.message_level,
        posted: this.posted || false,
        posted_date: this.posted_date,
        user_group: this.user_group,
        filters: JSON.stringify(this.filters || DEFAULT_FILTERS_STRING),
        post_message: posted || false
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_USER_MESSAGE_HISTORY, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_USER_MESSAGE_HISTORY, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.title_ = payload.title
              this.message = payload.message
              this.show_end_date = payload.show_end_date || null
              this.message_level = payload.message_level || 1
              this.posted = payload.posted
              this.posted_date = payload.posted_date
              this.user_group = payload.user_group
              this.filters = JSON.parse(payload.filters || JSON.stringify(DEFAULT_FILTERS_STRING))

              this.tab = 0
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(DELETE_USER_MESSAGE_HISTORY, this.itemId)
                    .then(ok => {
                      if (ok) {
                        this.closeModal()
                      }
                    })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-tabs {
  :deep(.v-window.v-item-group.theme--light) {
    background-color: transparent;
  }

  :deep(div[role="tablist"]) {
    background-color: #ffffff !important;
  }
}
</style>
